<template>
    <div id="legal-document">
        <a href="/">
            <img src="@/assets/logo.png" alt="Logo RobynGoods" class="logo">
        </a>
        <template v-if="!content">
            <p style="text-align: center;">...</p>
        </template>
        <template v-else>
            <h1>{{ title }}</h1>
            <div v-if="content" class="content" v-html="content"></div>
        </template>
    </div>
</template>
    
<script>
    export default {
        props: {
            legalDocument: {
                type: String,
                required: true
            },
            language: {
                type: String,
                required: true
            },
        },
        data() {
            return {
                title: '',
                content: ''
            };
        },
        async created() {
            await this.fetchContent();
        },
        methods: {
            async fetchContent() {
                const url = `https://rg-cfw-dev.appspot.com/webapp/pages/content?page=/politicas/${this.ecmPage}&clientId=robyngoods-dev&language=${this.language}`;

                try {
                    const response = await fetch(url);
                    const data = await response.json();

                    if (data.success && data.data) {
                        this.title = data.data.title.replace('1. ', '');
                        this.content = data.data.html;
                    }

                } catch (error) {
                    console.error("Error fetching data: ", error);
                }
            },
        },
        computed: {
            ecmPage() {
                let ecmPageSlug = '';

                switch (this.legalDocument) {
                    case 'privacy-policy':
                        if (this.language === 'es') {
                            ecmPageSlug = 'es/seguridad-privacidad/privacidad';
                        } else {
                            ecmPageSlug = 'en/security-privacy/privacy';
                        }

                        break;

                    case 'terms-of-use':
                        if (this.language === 'es') {
                            ecmPageSlug = 'es/terminos-condiciones/terminos-uso';
                        } else {
                            ecmPageSlug = 'en/terms-conditions/terms-use';
                        }

                        break;

                    default:
                        if (this.language === 'es') {
                            ecmPageSlug = 'es/seguridad-privacidad/privacidad';
                        } else {
                            ecmPageSlug = 'en/security-privacy/privacy';
                        }

                        break;
                }

                return ecmPageSlug;
            }
        }
    };
</script>

<style scoped lang="scss">
    #legal-document {
        padding: 0 2%;
        max-width: 1280px;
        width: 100%;
        margin: 0 auto;
        background-color: #ffffff;

        .logo {
            display: block;
            max-width: 200px;
            margin: 0 auto;
        }

        h1 {
            text-align: center;
        }

        .content {
            padding: 40px;
            margin: 30px auto;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
            border-radius: 25px;
            background-color: #fff;

            p {
                font-family: 'TT-Commons-Regular', sans-serif !important;
                font-size: 18px;
                line-height: 1.6;
                color: #333;
                margin: 15px 0;
            }

            /* Estilos para encabezados */
            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
                font-family: 'TT-Commons-Regular', sans-serif !important;
                color: #444;
                margin-top: 20px;
                margin-bottom: 10px;
            }

            /* Estilos para enlaces */
            a {
                color: #80b537;
                text-decoration: none;
            }

            a:hover {
                text-decoration: underline; 
            }

            /* Estilos para negritas */
            strong {  
                font-weight: bold;
            }

            /* Estilos para subrayados */
            u {
                text-decoration: underline;
            }

            /* Estilos para listas */
            ul,
            ol {
                margin: 15px 0;
                padding-left: 40px;
            }

            /* Estilos para tablas */
            table {
                width: 100%;
                border-collapse: collapse;
                margin: 20px 0;
            }

            th,
            td {
                border: 1px solid #ddd;
                padding: 10px; /* Añadir relleno a las celdas */
            }

            th {
                background-color: #f2f2f2;
                text-align: left;
            }
        }
    }
</style>