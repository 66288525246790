import Vue from 'vue';
import Router from 'vue-router';
import Landing from './components/LandingComponent.vue';
import LegalDocument from './components/LegalDocument.vue';

Vue.use(Router);

export default new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            component: Landing
        },
        {
            path: '/terminos-de-uso',
            name: 'LegalDocument',
            component: LegalDocument,
            props: {
                language: 'es',
                legalDocument: 'terms-of-use'
            }
        },
        {
            path: '/terms-of-use',
            name: 'LegalDocument',
            component: LegalDocument,
            props: {
                language: 'en',
                legalDocument: 'terms-of-use'
            }
        },
        {
            path: '/politica-de-privacidad',
            name: 'LegalDocument',
            component: LegalDocument,
            props: {
                language: 'es',
                legalDocument: 'privacy-policy'
            }
        },
        {
            path: '/privacy-policy',
            name: 'LegalDocument',
            component: LegalDocument,
            props: {
                language: 'en',
                legalDocument: 'privacy-policy'
            }
        },
        {
            path: '*',
            redirect: '/'
        }
    ]
});
