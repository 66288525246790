<template>
  <div class="image-container">
    <img :src="imageSrc" alt="Main Image" class="main-image">
  </div>
</template>

<script>
import mainImageEsp from '@/assets/main-image_esp.png';
import mainImageEng from '@/assets/main-image_eng.png';

export default {
  name: 'LandingRightComponent',
  props: {
    language: {
      type: String,
      required: true
    }
  },
  computed: {
    imageSrc() {
      return this.language === 'es' ? mainImageEsp : mainImageEng;
    }
  }
};
</script>

<style scoped>
.image-container {
  width: 100%;
  height: 100%;
  margin-top: 2%;
}
.main-image {
  width: 95%;
}


  /* Estilos para pantallas menores o iguales a 960px */
  @media (max-width: 959px) {
  .main-image {
    width: 95%;
    margin: 0px 4% auto !important;
  }
}


  /* Estilos específicos para pantallas de 1024px de ancho IPAD MINI HORIZONTAL*/
  @media (min-width: 1024px) and (max-width: 1024px) {
  .main-image {
    width: 95%;
    margin: 18% 4% auto !important;
  }
}


  /* Estilos específicos para pantallas de 1024px de ancho IPAD AIR HORIZONTAL*/
  @media (min-width: 1180px) and (max-width: 1180px) {
  .main-image {
    width: 95%;
    margin: 10% 4% auto !important;
  }
}
</style>
